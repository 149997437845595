import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';

@Injectable({
  providedIn: 'root',
})
export class CadastroGeralService {
  constructor(private api: ApiService) {}

  get() {
    return this.api.get<CadastroGeral>('/cadastroGeral/buscar');
  }
}
