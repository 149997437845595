import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonContainerComponent } from './components/button-container/button-container.component';
import { MaterialModule } from '../material/material.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { UploadSectionComponent } from './components/upload-section/upload-section.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { DatePickerHeaderComponent } from './components/date-picker-header/date-picker-header.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ButtonContainerComponent,
    DialogComponent,
    UploadSectionComponent,
    ImageUploadComponent,
    DatePickerHeaderComponent,
    DatePickerComponent,
    OtpInputComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [
    ButtonContainerComponent,
    DialogComponent,
    UploadSectionComponent,
    ImageUploadComponent,
    DatePickerComponent,
    OtpInputComponent,
  ],
})
export class SharedModule {}
