import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthRootComponent } from './auth/auth-root/auth-root.component';
import { GuestGuard } from './guards/guest.guard';
import { AdminRootComponent } from './admin/admin-root/admin-root.component';
import { LegalRootComponent } from './legal/legal-root/legal-root.component';
import { WelcomeComponent } from 'src/app/welcome/pages/welcome/welcome.component';

const routes: Routes = [
  {
    path: 'legal',
    component: LegalRootComponent,
    loadChildren: () =>
      import('./legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'auth',
    component: AuthRootComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [GuestGuard],
  },
  {
    path: '',
    component: AdminRootComponent,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
