import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Usuario } from '../models/usuario.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from './helper.service';
import { environment } from '@environments/environment';
import { UsuarioCliente } from '@models/usuario-cliente.model';
import { FetchProps } from '../utils';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '';
  isRecuperarSenha: boolean = false;
  user: Usuario = new Usuario();

  usuariosCliente: UsuarioCliente[] = [];

  usuarioCliente = new UsuarioCliente();

  readonly usuarioClienteChange = new EventEmitter<UsuarioCliente>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public http: HttpClient,
    public router: Router,
    private toastService: ToastrService,
    private helper: HelperService
  ) {
    this.loadStorage();
  }

  async signIn(params: { usuario: string; senha: string }) {
    const res = await this.helper.subscribeToObservable(
      this.http.get<Usuario>(environment.baseUrl + '/usuario/autenticar', {
        params,
      })
    );

    if (!res?.autenticacao?.sucesso) {
      this.toastService.error(res?.autenticacao?.erro);
      return 'failure';
    }

    if (res.indCliente !== 'S') {
      this.toastService.error(
        'Seu usuário não tem permissão para acessar essa area do sistema!'
      );
      return 'failure';
    }

    this.user = res;
    this.setToken(btoa(params?.usuario + ':' + params?.senha));
    this.setUser(res);

    await firstValueFrom(this.getUsuariosCliente());

    return 'success';
  }

  async forgotPassword(email: string) {
    const exists = await this.helper.subscribeToObservable(
      this.http.get<boolean>(environment.baseUrl + '/usuario/existeEmail', {
        params: {
          id: -99,
          email,
          operacao: 'insert',
        },
      })
    );

    if (!exists) {
      this.toastService.error(
        'Esse e-mail não existe ou nunca foi cadastrado.',
        'Esqueceu senha'
      );
      return 'failure';
    }

    const res = await this.helper.subscribeToObservable(
      this.http.get<string>(
        environment.baseUrl + '/usuario/codigoVerificacao',
        {
          params: {
            id: -99,
            email,
            operacao: 'insert',
          },
        }
      )
    );

    if (res !== 'Ok') {
      return 'failure';
    }

    return 'success';
  }

  async updatePassword(params: {
    usuario: string;
    senha: string;
    codigoVerificacao: string;
  }) {
    const res = await this.helper.subscribeToObservable(
      this.http.get<Usuario>(environment.baseUrl + '/usuario/alterarSenha', {
        params,
        withCredentials: true,
      })
    );

    if (!res?.id) return;

    res.senha = params?.senha;
    return res;
  }

  loadStorage() {
    if (isPlatformBrowser(this.platformId)) {
      const userStorage = localStorage.getItem('user_chappa');
      const user = JSON.parse(userStorage) as Usuario;
      if (user?.id) {
        this.user = user;
      }

      const usuarioClienteStorage = localStorage.getItem('user_cliente_chappa');
      const usuarioCliente = JSON.parse(
        usuarioClienteStorage
      ) as UsuarioCliente;

      if (usuarioCliente?.id) {
        this.usuarioCliente = usuarioCliente;
      }
    }
  }

  getTokenStorage() {
    let token = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('token_chappa');
    }

    return token;
  }

  setToken(token: string) {
    if (!token) return;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token_chappa', token);
    }
  }

  setUser(user: Usuario) {
    if (!user) return;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('user_chappa', JSON.stringify(user));
      this.user = user;
    }
  }

  setUsuarioCliente(usuarioCliente: UsuarioCliente) {
    if (!usuarioCliente?.id) return;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(
        'user_cliente_chappa',
        JSON.stringify(usuarioCliente)
      );
      this.usuarioCliente = usuarioCliente;
      this.usuarioClienteChange.emit(usuarioCliente);
    }
  }

  getUsuariosCliente() {
    return this.http
      .get<FetchProps<UsuarioCliente>>(
        environment.baseUrl + '/usuarioCliente/buscar',
        {
          params: { idUsuario: this.user?.id },
        }
      )
      .pipe(
        map((res) => {
          this.usuariosCliente = res?.data;

          if (
            res?.data?.length > 0 &&
            !this.usuarioCliente?.id &&
            !res?.data
              ?.map((item) => item?.id)
              ?.includes(this.usuarioCliente?.id)
          ) {
            this.setUsuarioCliente(res?.data?.[0]);
          }

          return res;
        })
      );
  }

  logout() {
    this.user = new Usuario();
    this.usuarioCliente = new UsuarioCliente();
    this.usuariosCliente = [];

    localStorage.clear();
    this.router.navigate(['/auth/sign-in']);
  }

  isLoggedIn() {
    if (isPlatformServer(this.platformId)) return;

    const token = this.getTokenStorage();

    return !!token;
  }
}
