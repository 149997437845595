import { HttpProgressEvent } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GlobalService } from '@services/global.service';
import { UploadService } from '@services/shared/upload-section.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input('label') label?: string;
  @Input('src') src: string = 'assets/image-placeholder.png';
  @Input('fileName') fileName: string = 'imagem';
  @Output('onUploadImage') onUploadImage = new EventEmitter<string>();

  file: File;
  progress = 0;

  constructor(
    public helper: HelperService,
    private loadingService: LoadingService,
    private uploadService: UploadService,
    public global: GlobalService
  ) {}

  ngOnInit(): void {}

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef | undefined;
  onFileSelected(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.file = event.target.files?.item(0);
    }
    this.loadingService.present('0%');
    if (!this.file) {
      this.loadingService.dismiss();
      return;
    }
    this.submit(this.file)
      .then((res: any) => {
        if (res.body) {
          this.onUploadImage.next(res.body);
        }
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        if (this.fileInput) {
          this.fileInput.nativeElement.value = '';
        }
        this.loadingService.dismiss();
      });
  }

  submit(file: File) {
    return new Promise((resolve, reject) => {
      this.uploadService.upload(file, 'image').subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        },
        (err) => reject(err)
      );
    });
  }

  removeImage() {
    this.onUploadImage.next('');
  }
}
