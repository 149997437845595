import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

// material
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { GuestGuard } from './guards/guest.guard';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PipesModule } from './pipes/pipes.module';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { HotToastModule } from '@ngneat/hot-toast';
import { initializeApp } from './helpers/app.initializer';
import { AuthService } from '@services/auth.service';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatSnackBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    MaterialModule,
    SharedModule,
    NgxMatSelectSearchModule,
    PipesModule,
    NgxGpAutocompleteModule,
    HotToastModule.forRoot({
      position: 'top-right',
    }),
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
  bootstrap: [AppComponent],
  exports: [
    FormsModule,
    MaterialModule,
    SharedModule,
    NgxMaskModule,
    NgxMatSelectSearchModule,
    PipesModule,
    NgxGpAutocompleteModule,
    ReactiveFormsModule,
  ],
})
export class AppModule {}
