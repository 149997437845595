<header class="header">
  <nav class="header-nav">
    <button class="header-menu__toggle hidden-desktop" (click)="open = !open">
      <i class="fas fa-bars" *ngIf="!open"></i>
      <i class="fas fa-times" *ngIf="open"></i>
    </button>

    <div class="header-menu" [class.active]="open">
      <!-- <div class="header-user">
        <div class="avatar">
          <img src="assets/icon.png" alt="Chappa" />
        </div>
        <h3 class="header-user__name">
          Olá, <span>{{ auth.user.nome.split(" ")[0] }}</span>
        </h3>
      </div> -->
      <ul>
        <li class="header-menu__item">
          <a routerLink="/" [routerLinkActiveOptions]="{ exact: true }" class="header-menu__link" (click)="open = false"
            routerLinkActive="active">
            <i class="fas fa-home"></i>
            <span>Home</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/historico" class="header-menu__link" routerLinkActive="active" (click)="open = false">
            <i class="fas fa-clock"></i>
            <span>Histórico</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/necessidade-chappa" class="header-menu__link" routerLinkActive="active"
            (click)="open = false">
            <i class="fas fa-leaf"></i>
            <span>Necessidades Chappa</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/usuarios" [queryParams]="{ ind: 'indCliente' }" class="header-menu__link"
            routerLinkActive="active" (click)="open = false">
            <i class="fas fa-user-tie"></i>
            <span>Usuários</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/usuarios" routerLinkActive="active" [queryParams]="{ ind: 'indMotorista' }"
            class="header-menu__link" (click)="open = false">
            <i class="fas fa-car"></i>
            <span>Motoristas</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/contas-a-receber" routerLinkActive="active" class="header-menu__link" (click)="open = false">
            <i class="fas fa-cash-register"></i>
            <span>Financeiro</span>
          </a>
        </li>
        <li class="header-menu__item" *ngIf="this.auth.user?.cliente?.id == 57">
          <a routerLink="/ctes" routerLinkActive="active" class="header-menu__link" (click)="open = false">
            <i class="fas fa-file"></i>
            <span>CTEs</span>
          </a>
        </li>
      </ul>
      <ul style="border-top: 2px solid #eee">
        <li class="header-menu__item">
          <a class="header-menu__link" [href]="'https://wa.me/55' + cadastroGeral?.whatsApp" target="_blank">
            <i class="fab fa-whatsapp"></i>
            <span>Entre em contato</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/legal/privacy" class="header-menu__link" (click)="open = false">
            <i class="fas fa-file"></i>
            <span>Política de privacidade</span>
          </a>
        </li>
        <li class="header-menu__item">
          <a routerLink="/legal/terms" class="header-menu__link" (click)="open = false">
            <i class="fas fa-file-lines"></i>
            <span>Termos de uso</span>
          </a>
        </li>
      </ul>
      <!-- <button class="header-menu__item">
        <a class="header-menu__link" (click)="auth.logout()">
          <i class="fas fa-sign-out-alt"></i>
          <span>Sair</span>
        </a>
      </button> -->
    </div>
    <div class="header-menu__backdrop" [class.active]="open" (click)="open = false"></div>
  </nav>
</header>
