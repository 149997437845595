import { Inject, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

export class MetaTags {
  nome: string = 'Chappa | Carga e descarga é com a gente!';
  descricaoSimples: string =
    'Quer uma opção ágil e em conta para realizar o transporte das cargas do seus clientes?';
  imagem: string = 'https://chappa.com.br/assets/avatar.png';
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  moment;
  emailRegex: RegExp;
  urlRegex: RegExp;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private _meta: Meta,
    private _title: Title
  ) {
    moment.locale('pt-BR');
    this.moment = moment;
    this.emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3200,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['white-snackbar'],
    });
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c: any) => this.formMarkAllTouched(c));
      }
    });
  }

  convertToSlug(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâãèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  buscarEstados() {
    return this.http.get(`${environment.baseUrl}/site/uf/buscar`);
  }

  buscarCidades(siglaUf: string, filtro: string = '%') {
    return this.http.get(
      `${environment.baseUrl}/site/Cidade/Buscar?siglaUf=${siglaUf}&filtro=${filtro}`
    );
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  updateTags(metatags?: MetaTags) {
    const data = {
      ...new MetaTags(),
      ...metatags,
    };
    const shareUrl: string = `https://telmaster.com.br`;
    this._title.setTitle(data.nome);
    this._meta.updateTag({ name: 'title', content: data.nome });
    this._meta.updateTag({ name: 'subject', content: data.nome });
    this._meta.updateTag({
      name: 'description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ name: 'url', content: shareUrl });
    this._meta.updateTag({ name: 'image', content: data.imagem });
    this._meta.updateTag({ property: 'og:title', content: data.nome });
    this._meta.updateTag({
      property: 'og:description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ property: 'og:url', content: shareUrl });
    this._meta.updateTag({ property: 'og:image', content: data.imagem });
    this._meta.updateTag({
      property: 'og:image:secure_url',
      content: data.imagem,
    });
    this._meta.updateTag({ property: 'og:image:alt', content: data.nome });
    this._meta.updateTag({ name: 'twitter:title', content: data.nome });
    this._meta.updateTag({
      name: 'twitter:description',
      content: data.descricaoSimples,
    });
    this._meta.updateTag({ name: 'twitter:url', content: shareUrl });
    this._meta.updateTag({ name: 'twitter:image', content: data.imagem });
    this._meta.updateTag({ name: 'twitter:image:src', content: data.imagem });
  }

  isValidCPF(cpf: string) {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[\s.-]*/gim, '');
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  compareFn(v1: any, v2: any): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }

  subscribeToObservable<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: (value) => {
          resolve(value); // Resolve the promise with the emitted value
          subscription.unsubscribe(); // Unsubscribe once value is received
        },
        error: (error) => {
          reject(error); // Reject the promise if there's an error
        },
      });
    });
  }

  removeNullKeys(params: Object) {
    for (let key in params) {
      if (
        params[key] === undefined ||
        params[key] === null ||
        params[key] === ''
      ) {
        delete params[key];
      }
    }
    return params;
  }
}
