import { Component, OnInit } from '@angular/core';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';
import { Configuracao } from 'src/app/models/configuracao.model';
import { AuthService } from 'src/app/services/auth.service';
import { CadastroGeralService } from 'src/app/services/class/cadastro-geral.service';
import { ConfiguracaoService } from 'src/app/services/class/configuracao.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  open: boolean = false;
  cadastroGeral = new CadastroGeral();

  constructor(
    public auth: AuthService,
    public cadastroGeralService: CadastroGeralService
  ) {}

  ngOnInit(): void {
    this.cadastroGeralService
      .get()
      .subscribe((res) => (this.cadastroGeral = res));
  }
}
