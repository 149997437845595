export class Cliente {
  id = 0;
  nome = '';
  email = '';
  celular = '';
  cnpj = '';
  situacao: 'A' | 'I' = 'A';
  dataHoraCriacao = new Date();
  status: 'P' | 'A' | 'R' = 'P';
  imagem = '';
  cep = '';
  uf = '';
  cidade = '';
  bairro = '';
  endereco = '';
  enderecoNumero = '';
  complemento = '';
}
